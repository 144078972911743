import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Typography,
  Avatar,
  Divider,
  Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { pluck } from 'ramda';

import { items } from '../utils';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ThumbnailItem from '../components/ThumbnailItem';
import CoverItem from '../components/CoverItem';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  avatar: {
    width: '96px',
    height: '96px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(4),
    },
  },
}));

const Page = ({ pageContext }) => {
  const classes = useStyles();

  const {
    speaker,
    sermons,
    articles,
    hasMoreSermons,
    hasMoreArticles,
  } = pageContext;

  const hasSermons = sermons.length > 0;
  const hasArticles = articles.length > 0;

  const layout = {
    sermons: {
      column: 8,
      item: 12,
      align: 'left',
    },
    articles: {
      column: 4,
      item: 12,
      align: 'left',
    },
  };

  if (hasSermons && !hasArticles) {
    layout.sermons.column = 12;
    layout.sermons.item = 6;
    layout.sermons.align = 'center';
  } else if (hasArticles && !hasSermons) {
    layout.articles.column = 12;
    layout.articles.item = 4;
    layout.articles.align = 'center';
  }

  return (
    <Layout>
      <SEO title={speaker.name} />
      <Box mb={6} className={classes.heading}>
        {speaker.imageAvatar && (
          <Avatar
            alt={speaker.name}
            src={speaker.imageAvatar}
            className={classes.avatar}
          >
            {speaker.name
              .split(' ')
              .map((w) => w.charAt(0))
              .join('')}
          </Avatar>
        )}
        <Typography variant="h1">{speaker.name}</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      {speaker.bio && (
        <Box textAlign="center" mb={8}>
          <Typography variant="body1">{speaker.bio}</Typography>
        </Box>
      )}

      <Grid container spacing={4}>
        {hasSermons && (
          <Grid item xs={12} md={layout.sermons.column}>
            <Box mb={4} textAlign={layout.sermons.align}>
              <Typography variant="h4">Media</Typography>
            </Box>
            <Grid
              container
              style={{
                justifyContent: sermons.length === 1 ? 'center' : 'flex-start',
              }}
            >
              {sermons.map((sermon) => (
                <Grid key={sermon.id} item xs={12} md={layout.sermons.item}>
                  <ThumbnailItem
                    title={sermon.title}
                    subtext={pluck('name', items(sermon.speakers)).join(', ')}
                    timestamp={sermon.date}
                    image={`https://i.ytimg.com/vi/${sermon.youtubeId}/hqdefault.jpg`}
                    to={`/media/sermons/${sermon.slug}`}
                  />
                </Grid>
              ))}
            </Grid>
            {hasMoreSermons && (
              <Box mt={6} mb={4} textAlign={!hasArticles ? 'center' : 'left'}>
                <Button
                  component={Link}
                  to={`/speakers/${speaker.slug}/sermons/2`}
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  More media
                </Button>
              </Box>
            )}
          </Grid>
        )}

        {hasArticles && (
          <Grid item xs={12} md={layout.articles.column}>
            <Box mb={4} textAlign={layout.articles.align}>
              <Typography variant="h4">Articles</Typography>
            </Box>
            <Grid
              container
              style={{
                justifyContent: articles.length < 3 ? 'center' : 'flex-start',
              }}
              spacing={2}
            >
              {articles.map((article) => (
                <Grid key={article.id} item xs={12} md={layout.articles.item}>
                  <CoverItem
                    title={article.title}
                    subtext={article.author.name}
                    timestamp={article.date}
                    image={article.imageThumb}
                    to={`/articles/${article.slug}`}
                  />
                </Grid>
              ))}
            </Grid>
            {hasMoreArticles && (
              <Box mt={6} mb={4} textAlign={!hasSermons ? 'center' : 'left'}>
                <Button
                  component={Link}
                  to={`/speakers/${speaker.slug}/articles/2`}
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  More articles
                </Button>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Page;
